/* eslint-disable react/prop-types */
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';

import { ImQuotesLeft } from '@react-icons/all-files/im/ImQuotesLeft';
import { ImQuotesRight } from '@react-icons/all-files/im/ImQuotesRight';
import Layout from '../components/Layout/Layout';

const ImpactPage = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Impact | Presidium Health - Home-based Healthcare"
        description="Presidium Health provides home-based care services to patients."
        metaTags={[
          {
            name: 'keywords', 
            content: 'home-based healthcare, value-based healthcare, patient care, telemedicine'
          }
        ]}
      />

      <div className="hero impact-hero d-flex align-items-lg-center p-relative pt-3 pt-lg-0">
        <Container>
          <Row>
            <Col lg={5}>
              <h1 className="text-primary fw-bold fs-2">
                Individualized Care Meets Data Analytics
              </h1>
              <p>
                Combining best in class holistic care with cutting edge data
                analytics, allows us to successfully help patients the health
                system struggles with
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <section className="bg-gray py-5">
        <Container>
          <Row className="text-center">
            <Col>
              <h2 className="text-primary fw-bold fs-2">
                We Succeed Where Others Fail
              </h2>
            </Col>
          </Row>
        </Container>
      </section>

      <Container className="py-3 py-md-5 my-5">
        <Row className="justify-content-center">
          <Col>
            <div className="box-wrapper">
              <div className="d-flex align-items-center justify-content-center flex-column flex-lg-row">
                <div className="presidium-box py-5 px-2 bg-white shadow-lg d-flex align-items-center justify-content-center text-primary rounded-3 fw-bold fs-3">
                  TOP 1% UTILIZER
                </div>
                <div className="ui-square-sign mx-2">+</div>
                <div className="presidium-box p-5 bg-primary shadow-primary d-flex align-items-center justify-content-center text-primary rounded-3 fw-bold fs-5">
                  <StaticImage
                    src="../images/white-logo.svg"
                    alt="Population"
                    placeholder="blurred"
                    width={80}
                  />
                </div>
                <div className="ui-square-sign equal-sign">=</div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <section className="bg-gray py-3 py-md-5" id="engament">
        <Container>
          <Row className="align-items-center justify-content-center my-5">
            <Col lg={4}>
              <div className="mb-4 mb-lg-0 p-5 bg-primary text-white d-flex align-items-center justify-content-center flex-column flex-xl-row shadow-primary rounded-3">
                <div className="fs-1 fw-bold">90%</div>
                <p className="m-0 text-uppercase fs-6 ps-lg-4 flex-basis-0 text-center text-lg-start">
                  Patient
                  <br />
                  Engagement
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="p-5 bg-white text-primary shadow-lg d-flex align-items-center justify-content-center flex-column flex-xl-row rounded-3">
                <div className="fs-1 fw-bold">98%</div>
                <p className="m-0 text-uppercase fs-6 ps-lg-4 flex-basis-0  text-center text-lg-start">
                  Patient
                  <br />
                  Retention
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container className="py-3 py-md-5" id="utilization">
        <Row className="align-items-center justify-content-center my-5">
          <Col xl={8}>
            <div className="p-5 bg-primary text-white d-flex flex-column flex-md-row align-items-center justify-content-center shadow-primary rounded-3 my-lg-5">
              <p className="m-0 fs-6 text-uppercase">Nearly</p>
              <p className="m-0 fs-1 fw-bold px-4">50%</p>
              <p className="m-0 fs-6 text-uppercase text-center text-md-start">
                Reduction in Hospital Utilization
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <section className="ui-testimonials d-flex align-items-center">
        <Container>
          <Row>
            <Col>
              <Carousel indicators={false}>
                <Carousel.Item>
                  <Row className="justify-content-center">
                    <Col md={6} xl={6} className="position-relative">
                      <ImQuotesLeft
                        size={40}
                        className="text-white position-absolute ui-testimonials-quote"
                      />
                      <p className="m-0 text-white fs-5 ps-lg-5  p-4 pt-5 fst-italic text-center">
                        Presidium Health has been great! The doctors and staff
                        are there when you need them. It has made our lives so
                        much easier. The care now comes to our home, this saves
                        us a lot of stress and time of not having to prep for
                        the trip, coordinate transportation and get back and
                        forth to the hospital.
                      </p>
                      <ImQuotesRight
                        size={40}
                        className="text-white position-absolute ui-testimonials-quote ui-testimonials-quote-right"
                      />
                      <p className="text-white ps-md-5 mt-5 text-center">
                        <small>
                          Presidium Health Patient
                          <br />
                          Frances Valaile
                        </small>
                      </p>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item>
                  <Row className="justify-content-center">
                    <Col md={6} xl={6} className="position-relative">
                      <ImQuotesLeft
                        size={40}
                        className="text-white position-absolute ui-testimonials-quote"
                      />
                      <p className="m-0 text-white fs-5 ps-lg-5  p-4 pt-5 fst-italic text-center">
                        This service has changed my life for the better!
                        Presidium Health makes me stronger not weaker! They
                        provide me with the highest quality of care in my home.
                        I wish I knew about their service years ago. I am so
                        grateful to be part of Presidium Health care service.
                      </p>
                      <ImQuotesRight
                        size={40}
                        className="text-white position-absolute ui-testimonials-quote ui-testimonials-quote-right"
                      />
                      <p className="text-white ps-md-5 mt-5 text-center">
                        <small>
                          Presidium Health Patient
                          <br />
                          Juanita Rodríguez
                        </small>
                      </p>
                    </Col>
                  </Row>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default ImpactPage;
